import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import * as styles from "./PrivacyPolicyPage.module.scss";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

function PrivacyPolicyPageTemplate() {
    const data = useStaticQuery(
        graphql`
            query PrivacyPolicyPageTemplate {
                strapiPrivacyPolicyPage {
                    heading
                    nav
                    contents {
                        title
                        paragraph
                        paragraphHTML
                    }
                }
            }
        `
    );

    const { strapiPrivacyPolicyPage } = data;

    return (
        <>
            <section className={styles.heroWrapper}>
                <div className="mainBox">
                    <h1 className="font-serif">{strapiPrivacyPolicyPage?.heading}</h1>
                </div>
            </section>

            <div className={styles.contentWrapper}>
                {strapiPrivacyPolicyPage.contents.map((content: any) => {
                    return (
                        <div key={content.title}>
                            <h2>{content.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: content.paragraphHTML || content.paragraph }} />
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default PrivacyPolicyPageTemplate;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id="site-title">{seo.title}</title>
            <meta id="desc" name="description" content={seo.description} />
        </SiteMetadataHead>
    );
}
